<template>
  <div>
    <h2 style="font-family: cabin_sketch_bold;font-size: 40px" class="title has-text-centered">
      {{ $t('sketchy.page_jeu.etat_4.titre') }}</h2>

    <div class="has-text-centered musee-container" @mousemove="dynCursorMove" ref="museeContainer">
      <div style="display: inline-block;" class="tabs tabs-carnets" v-if="e4_auteurs !== null">
        <ul>
          <li
              :class="{'is-active': carnet_ouvert === i}"
              v-for="(curAuteurs, i) in e4_auteurs"
              @click="ouvrirCarnet(i)"
          >
            <a>
              <template v-if="gameOptions.longer_games || e4_auteurs.length % 2 === 0">{{ curAuteurs[0] }}</template>
              <template v-else>{{ e4_auteurs[(e4_auteurs.length + i - 1) % e4_auteurs.length][0] }}</template>
            </a>
            <div class="mouse-indicators">
              <span
                  v-for="cursor in cursors"
                  v-if="cursor.o === i"
                  style="background-color: red;margin-right: 4px;"
                  :style="{
                  filter: 'grayscale(40%) brightness(600%) hue-rotate(' + cursor.ij * 60 + 'deg)',
                }"
              ></span>
            </div>
          </li>
        </ul>
      </div>
      <br/>

      <div
        style="display: inline-block;width: 100%;"
        class="carnet" v-if="e4_auteurs !== null && e4_mots !== null"
        :class="{
          'frosted-background-static': $isSafari,
          'frosted-background': !$isSafari,
        }"
      >

        <template v-for="(mot, i) in mots" class="is-center">
          <div v-if="i>0" class="pic-container">
            <img class="pic" :src="$url_serveur + '/salon/'+id_salon+'/carnet/'+carnet_ouvert+'/dessin/'+(i-1)">
            <span
              v-if="e4_mots_scores !== null"
              class="pic-score"
              :style="{color: couleurScore(scores[i - 1])}"
            >{{ $tc('sketchy.page_jeu.etat_4.plus_$d_points', scores[i - 1]) }}</span>
            <span class="pic-author">{{ auteurs[i - 1] }}</span>
          </div>


          <div class="mot">
            <template v-if="i > 0">
              <FlecheBas style="margin-top: 12px;"/>
            </template>

            <div class="mot-container">
              <span class="lemot">
                {{ mot }}
                <span class="mot-author" v-if="i > 0">{{ mots_auteurs[i] }}</span>
              </span>

            </div>

            <template v-if="i+1 < mots.length">
              <FlecheBas/>
            </template>
          </div>

        </template>
      </div>

      <img
          v-for="cursor in cursors"
          v-if="cursor.o === carnet_ouvert"
          class="dyn-cursor"
          src="@/resources/images/mouse_red.png"
          :style="{
          top: cursor.y + 'px',
          left: cursor.x + 'px',
          filter: 'grayscale(40%) brightness(700%) hue-rotate(' + cursor.ij * 60 + 'deg)',
        }"
      />
    </div>

    <bouton-up @click="topFunction" class="btn-up" />
  </div>
</template>

<style lang="scss" scoped>
//@import '../../resources/style/helpers/nice_scrollbar';
@import '~@/resources/libraries/bulma-0.9.0/sass/utilities/_all.sass';

.btn-up{
  position: fixed;
  bottom: 20px;
  //left: calc(50% + 380px);
  right: 10px;
  height: 50px;
  width: auto;
  z-index: 15;

  @include mobile {
    display: none;
  }

}

.tabs {
  max-width: 640px;

  ul {
    flex-shrink: 1;
    flex-wrap: wrap;
    border-bottom-color: transparent;

    li {
      margin-bottom: 18px;
    }
  }
}


.musee-container {
  position: relative;

  .dyn-cursor {
    position: absolute;

    top: 0;
    left: 0;

    transition: top 100ms linear, left 100ms linear;
    pointer-events: none;
  }
}

.carnet {
  border: 1px rgba(0, 0, 0, 0.15) solid;
  border-radius: 10px;

  padding: 10px 18px;

  .mot {
    max-width: 650px;
    display: inline-block;
  }

  .mot-container {
    max-height: 150px;
    overflow-y: auto;

    &:not(:first-child) {
      padding-bottom: 25px;
    }
    &:first-child {
      padding-bottom: 10px;
    }

    .lemot {
      position: relative;

      font-family: cabin_sketch_regular;
      font-size: 36px;
      word-break: break-word;
      line-height: 40px;
      //word-wrap: break-word;

      .mot-author {
        display: block;
        position: absolute;
        bottom: -24px;
        right: 0;
        margin-right: 8px;
        color: #aaa;
        font-family: cabin_sketch_regular;
        font-size: 16px;
        white-space: nowrap;
      }
    }
  }

  .pic-container {
    position: relative;

    .pic {
      display: block;

      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      margin-left: 10px;
      margin-right: 10px;

      overflow: hidden;
      background-color: #fff;
    }

    .pic-score {
      display: block;
      position: absolute;
      bottom: -42px;
      left: 0;
      margin-left: 26px;
      color: #0f9200;
      font-family: cabin_sketch_regular;
      font-size: 24px;
    }

    .pic-author {
      display: block;
      position: absolute;
      bottom: -28px;
      right: 0;
      margin-right: 22px;
      color: #aaa;
      font-family: cabin_sketch_regular;
      font-size: 19px;
    }
  }
}

$taille-indicateurs-souris: 24px;
.tabs-carnets {
  ul {
    margin-bottom: $taille-indicateurs-souris;

    li {
      position: relative;

      .mouse-indicators {
        position: absolute;

        bottom: -$taille-indicateurs-souris + 3px;
        left: 0;

        width: 100%;
        height: $taille-indicateurs-souris;

        text-align: center;
        pointer-events: none;

        > span {
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: black;
          border-radius: 8px;

          pointer-events: none;
        }
      }
    }
  }
}
</style>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'Etat4Fin',

  data() {
    return {
      carnet_ouvert: 0,

      cursorX: 0,
      cursorY: 0,

      compteur: 0,
      lastEvt: null,

      cursors: {},

      dynCursorInterval: null,
    };
  },

  mounted() {
    this.dynCursorInterval = setInterval(() => {
      this.dynCursorCalculate(this.lastEvt, true);
    }, 80);
  },

  beforeDestroy() {
    if (this.dynCursorInterval !== null)
      clearInterval(this.dynCursorInterval);
  },

  methods: {
    topFunction() {
      this.$bus.$emit("scrollTop");
    },

    onMsg(msg_type, msg_data) {
      if (msg_type === 'e4_cur') {
        const ij = msg_data[0];

        if (!(ij in this.cursors)) {
          this.$set(this.cursors, ij, {
            ij: ij,
            o: msg_data[1],
          });
        } else {
          this.$set(this.cursors[ij], 'o', msg_data[1]);
        }

        if (msg_data.length === 4) {
          this.$set(this.cursors[ij], 'x', msg_data[2]);
          this.$set(this.cursors[ij], 'y', msg_data[3]);
        }
      }

      if (msg_type === 'e4_cur_depop') {
        this.$delete(this.cursors, msg_data);
        let newCursors = {};

        for (let i in this.cursors) {
          if (i >= msg_data) {
            newCursors[i - 1] = this.cursors[i];
            newCursors[i - 1].ij = i - 1;
          } else {
            newCursors[i] = this.cursors[i];
          }
        }

        this.$set(this, 'cursors', newCursors);
        console.info('traitement cursor depop');
      }
    },

    ouvrirCarnet(c) {
      this.carnet_ouvert = c;
      this.$emit('sendMsg', 'e4_cur', [this.carnet_ouvert]);
    },

    dynCursorMove(e) {
      this.dynCursorCalculate(e);
    },

    dynCursorCalculate: debounce(function (e, forced = undefined) {
      if (e === null) return;
      this.lastEvt = e;

      if (this.dynCursorInterval === null) return;
      if (!('museeContainer' in this.$refs)) return;
      if (this.$refs.museeContainer === undefined || this.$refs.museeContainer === null) return;

      const left = this.$refs.museeContainer.getBoundingClientRect().left;
      const top = this.$refs.museeContainer.getBoundingClientRect().top;

      let offsetX = e.pageX - left;
      let offsetY = e.pageY - top;

      const cursorX = offsetX;
      const cursorY = offsetY;

      if (forced === true) {
        if (this.cursorX !== cursorX || this.cursorY !== cursorY) {
          this.cursorX = cursorX;
          this.cursorY = cursorY;
          this.compteur++;
          this.$emit('sendMsg', 'e4_cur', [this.carnet_ouvert, Math.round(cursorX), Math.round(cursorY)]);
        }
      } else {
        let diffX = cursorX - this.cursorX;
        if (diffX < 0) diffX = -diffX;

        let diffY = cursorY - this.cursorY;
        if (diffY < 0) diffY = -diffY;

        if (diffX > 6 || diffY > 6) {
          if (this.cursorX !== cursorX || this.cursorY !== cursorY) {
            this.cursorX = cursorX;
            this.cursorY = cursorY;
            this.compteur++;
            this.$emit('sendMsg', 'e4_cur', [this.carnet_ouvert, Math.round(cursorX), Math.round(cursorY)]);
          }
        }
      }
    }, 50, {maxWait: 50}),

    couleurScore(score) {
      let v = parseInt(score) / 100.0;
      v *= v + 0.2;
      v *= 100.0;
      v += 10;

      if (v > 110) v = 110;
      return 'hsl(' + Math.round(v) + ', 100%, 36%)';
    },
  },

  computed: {
    mots() {
      if (this.e4_mots === null) return null;
      return this.e4_mots[this.carnet_ouvert];
    },

    auteurs() {
      if (this.e4_auteurs === null) return null;
      return this.e4_auteurs[this.carnet_ouvert];
    },

    scores() {
      if (this.e4_mots_scores === null) return null;
      return this.e4_mots_scores[this.carnet_ouvert];
    },

    mots_auteurs() {
      if (this.e4_mots_auteurs === null) return null;
      return this.e4_mots_auteurs[this.carnet_ouvert];
    },

    museeContainerLeft() {
      return this.$refs.museeContainer.getBoundingClientRect().left;
    },

    museeContainerTop() {
      return this.$refs.museeContainer.getBoundingClientRect().top;
    },
  },

  components: {
    FlecheBas: () => import('@/resources/images/FlecheBas'),
    BoutonDl: () => import('@/views/components/global/boutons/BoutonDl'),
    BoutonUp: () => import('@/views/components/global/boutons/BoutonUp'),

  },

  props: ['e4_mots', 'e4_auteurs', 'e4_mots_auteurs', 'id_salon', 'e4_mots_scores', 'gameOptions'],
}
</script>
