var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"title has-text-centered",staticStyle:{"font-family":"cabin_sketch_bold","font-size":"40px"}},[_vm._v(" "+_vm._s(_vm.$t('sketchy.page_jeu.etat_4.titre')))]),_c('div',{ref:"museeContainer",staticClass:"has-text-centered musee-container",on:{"mousemove":_vm.dynCursorMove}},[(_vm.e4_auteurs !== null)?_c('div',{staticClass:"tabs tabs-carnets",staticStyle:{"display":"inline-block"}},[_c('ul',_vm._l((_vm.e4_auteurs),function(curAuteurs,i){return _c('li',{class:{'is-active': _vm.carnet_ouvert === i},on:{"click":function($event){return _vm.ouvrirCarnet(i)}}},[_c('a',[(_vm.gameOptions.longer_games || _vm.e4_auteurs.length % 2 === 0)?[_vm._v(_vm._s(curAuteurs[0]))]:[_vm._v(_vm._s(_vm.e4_auteurs[(_vm.e4_auteurs.length + i - 1) % _vm.e4_auteurs.length][0]))]],2),_c('div',{staticClass:"mouse-indicators"},_vm._l((_vm.cursors),function(cursor){return (cursor.o === i)?_c('span',{staticStyle:{"background-color":"red","margin-right":"4px"},style:({
                filter: 'grayscale(40%) brightness(600%) hue-rotate(' + cursor.ij * 60 + 'deg)',
              })}):_vm._e()}),0)])}),0)]):_vm._e(),_c('br'),(_vm.e4_auteurs !== null && _vm.e4_mots !== null)?_c('div',{staticClass:"carnet",class:{
        'frosted-background-static': _vm.$isSafari,
        'frosted-background': !_vm.$isSafari,
      },staticStyle:{"display":"inline-block","width":"100%"}},[_vm._l((_vm.mots),function(mot,i){return [(i>0)?_c('div',{staticClass:"pic-container"},[_c('img',{staticClass:"pic",attrs:{"src":_vm.$url_serveur + '/salon/'+_vm.id_salon+'/carnet/'+_vm.carnet_ouvert+'/dessin/'+(i-1)}}),(_vm.e4_mots_scores !== null)?_c('span',{staticClass:"pic-score",style:({color: _vm.couleurScore(_vm.scores[i - 1])})},[_vm._v(_vm._s(_vm.$tc('sketchy.page_jeu.etat_4.plus_$d_points', _vm.scores[i - 1])))]):_vm._e(),_c('span',{staticClass:"pic-author"},[_vm._v(_vm._s(_vm.auteurs[i - 1]))])]):_vm._e(),_c('div',{staticClass:"mot"},[(i > 0)?[_c('FlecheBas',{staticStyle:{"margin-top":"12px"}})]:_vm._e(),_c('div',{staticClass:"mot-container"},[_c('span',{staticClass:"lemot"},[_vm._v(" "+_vm._s(mot)+" "),(i > 0)?_c('span',{staticClass:"mot-author"},[_vm._v(_vm._s(_vm.mots_auteurs[i]))]):_vm._e()])]),(i+1 < _vm.mots.length)?[_c('FlecheBas')]:_vm._e()],2)]})],2):_vm._e(),_vm._l((_vm.cursors),function(cursor){return (cursor.o === _vm.carnet_ouvert)?_c('img',{staticClass:"dyn-cursor",style:({
        top: cursor.y + 'px',
        left: cursor.x + 'px',
        filter: 'grayscale(40%) brightness(700%) hue-rotate(' + cursor.ij * 60 + 'deg)',
      }),attrs:{"src":require("@/resources/images/mouse_red.png")}}):_vm._e()})],2),_c('bouton-up',{staticClass:"btn-up",on:{"click":_vm.topFunction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }